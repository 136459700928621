<template>
  <nav class="grid-default col-span-6 lg:col-span-9">
    <SiteMapItem
      v-for="item in parents"
      :key="item.id"
      :data="item"
      class="col-span-6 lg:col-span-3"
    />
  </nav>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

// Get the top level menu items
const parents = computed(() => props.data.filter(item => item.parent === null))
</script>
