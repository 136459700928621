<template>
  <nav class="flex w-fit gap-6">
    <NuxtLink
      v-for="item in data"
      :key="item.id"
      :to="item.reference ? localePathByType('PageRecord', item.reference.slug) : item.externalLink"
      :target="item.externalLink ? '_blank' : undefined"
      class="block w-fit text-xs transition-colors duration-300 hover:text-neutral-400 lg:inline-block"
      noPrefetch
    >
      {{ item.title }}
    </NuxtLink>
  </nav>
</template>

<script setup lang="ts">
import type { MenuFooterRecord } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Array as PropType<MenuFooterRecord[]>
  }
})
</script>
