<template>
  <div>
    <a
      v-for="item in availableLocales"
      :key="item.code"
      :href="item.code === 'sl' ? '/' : '/' + item.code"
      :hreflang="item.code"
      rel="alternate"
      class="block text-xs"
      @click="onClick(item.code)"
    >
      {{ item.name }}</a
    >
  </div>
</template>

<script setup lang="ts">
/**
 * Its not pretty, but it gets the job done.
 * $i18n.availableLocales[0] is a workaround until we can get the default locale from the i18n module.
 * TODO: Stay on the same page on locale switch using NuxtLink!
 */
const { locale, locales } = useI18n()
const availableLocales = computed(() => locales.value.filter(i => i.code !== locale.value))

const onClick = (code: string) => {
  const country = useCookie('nf_country')
  country.value = code

  if (code === 'sl') {
    window.location.replace('/')
  } else {
    window.location.replace(`/${code}`)
  }
}
</script>
