<template>
  <div>
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'span'"
      :to="
        data.reference
          ? localePathByType('PageRecord', data.reference.slug)
          : data.externalLink
            ? data.externalLink
            : undefined
      "
      class="mb-4 block font-semibold"
      noPrefetch
    >
      {{ data.title }}
    </component>
    <ul
      v-if="Array.isArray(data.children) && data.children.length"
      class="mb-6 text-base text-neutral-400"
    >
      <li v-for="item in childrenFiltered" :key="item.id">
        <NuxtLink
          :to="
            item.reference ? localePathByType('PageRecord', item.reference.slug) : item.externalLink
          "
          class="my-[10px] block w-fit transition-colors duration-300 hover:text-light-gray"
          >{{ item.title }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const NuxtLink = resolveComponent('NuxtLink')
const { localePathByType } = useLocalePathByType()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

// Extract only published translations
const childrenFiltered = computed(() =>
  props.data.children.filter(x => x.publishTranslation === true)
)
</script>
