<template>
  <footer ref="target" class="bg-blue-900 pt-14 text-light-gray lg:pt-16">
    <div class="grid-default container pb-14 lg:pb-16">
      <Logo
        color="#D5DAD0"
        :height="isDesktop ? 26 : 32"
        :width="isDesktop ? 150 : 188"
        class="col-span-6 mb-6 lg:order-1 lg:my-auto"
      />
      <Heading semantic class="col-span-6 text-5xl font-light lg:order-3 lg:mt-10 lg:text-9xl">
        {{ $t('footer.title') }}
      </Heading>
      <div
        class="col-span-6 my-6 h-[2px] w-full bg-light-gray lg:order-4 lg:col-span-12 lg:my-14"
      />
      <SiteMap class="lg:order-5 lg:mb-28" :data="data.allMenus" />
      <div
        class="col-span-6 mb-9 flex flex-col gap-8 lg:order-2 lg:mb-0 lg:flex-row lg:items-center lg:justify-end"
      >
        <Social />
        <Button
          class="w-fit"
          :label="$t('footer.contactUs')"
          :to="$t('footer.buttonLink')"
          variant="solid"
          color="gray"
          icon
        />
      </div>
      <div class="col-start-1 col-end-7 mb-3 lg:order-5 lg:mb-0">
        <small class="whitespace-nowrap text-xs text-white"
          >@ {{ year }} {{ $t('copyright') }}</small
        >
      </div>
      <div class="col-span-6 flex items-center gap-6 lg:order-6 lg:justify-end">
        <FooterLinks :data="data.allMenuFooters" />
        <Language />
      </div>
    </div>
    <div v-if="isVisible && isDesktop">
      <LazyFooterVideo />
    </div>
  </footer>
</template>

<script setup lang="ts">

const { isDesktop } = useDevice()
const target = ref(null)
const isVisible = ref(false)

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const year = new Date().getFullYear()

const { stop } = useIntersectionObserver(
  target,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isVisible.value = true
    }
  },
  {
    threshold: 0.5
  }
)

onUnmounted(() => {
  stop()
})
</script>
