<template>
  <div class="flex w-[fit-content] gap-3">
    <CircleButton
      v-for="item in socialLinks"
      :key="item.name"
      target="_blank"
      rel="noopener noreferrer"
      :iconName="item.icon"
      :to="item.href"
      :title="item.title"
      color="blue-bordered"
    />
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '~/components/media/Icon.vue'
const { t } = useI18n()

type SocialLink = {
  name: string
  href: string
  icon: IconName
  title: string
}

const socialLinks = ref<SocialLink[]>([
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/@eurolAbrasives/',
    icon: 'play',
    title: t('social.links.youtube')
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/eurol.abrasives/',
    icon: 'instagram',
    title: t('social.links.instagram')
  },
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/eurol.si/',
    icon: 'facebook',
    title: t('social.links.facebook')
  }
])
</script>
